import React, {Component} from 'react';
import './SignupForm.scss';

export default class ContactForm extends Component {
  state = {
    success: false,
    error: false,
  }
  handleSubmit = e => {
    e.preventDefault()
    e.persist() // Lets keep the event around
    const {target} = e
    const formData = {
      email: target.email.value,
      'form-name': target['form-name'].value,
      'bot-field': target['bot-field'].value,
    }

    this.sendFormData(formData)
    .then(response => {
      if (response.ok) {
        this.setState({error: false, success: true})
        e.target.reset()
      } else {
        this.setState({error: true})
      }
    })
    .catch(err => {
      this.setState({error: true})
    })
  }

  sendFormData = json => {
    const body = Object.keys(json)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key]))
      .join('&')
    return fetch(
      '/signup/',
      {
        method: 'post',
        body: body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  }

  render() {
    const {error, success} = this.state
    return (
      <div
        id="signup-form"
        className="signup-form"
      >
        <div className="container">
          <div className="row">
            {
              success ?
              (
                <div className="col-12">
                  <h2 className="h5">See you soon!</h2>
                  <p>Keep an eye on your inbox. We will send you a confirmation.</p>
                </div>
              ) :
              (
                <div className="col-12">
                  <h2 className="h5">Enjoying our content?</h2>
                  <p>Signup below to receive updates about what we are up to.</p>
                  <form
                    onSubmit={this.handleSubmit}
                    name="signup"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    action="/signup/"
                    className="mb-3"
                  >
                    <input type="hidden" name="form-name" value="signup" />
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <div className="input-group">
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="jane.doe@example.org"
                          required="required"
                        />
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-primary">Sign up</button>
                        </div>
                      </div>
                      {
                        error &&
                        <span className="form-error">An error has occurred. Try reloading and signing up again.</span>
                      }
                    </div>
                    <label className="d-none">Don’t fill this out if you're human: <input name="bot-field"/></label>
                  </form>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
